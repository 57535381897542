<template>
  <div class="centerclass" v-wechat-title="$route.meta.title">
    <navigation :noleft="true" :msg="msg"></navigation>
    <div class="topimg"><img src="../../assets/shouquan.png" /></div>

    <div class="smclass">
      <div>信息核实</div>
  
    </div>

    <div class="msg2">
      <div>委托公司：{{ company }}</div>
      <div>24小时内未完成授权，本次核实过程作废</div>
    </div>

    <!-- <div class="desclass">
      <div class="divone"></div>
      <div>
        <p>背调说明</p>
      </div>
      <div class="divtwo"></div>
    </div>

    <p class="pcone">24小时内未完成授权，本次背调过程作废</p>
    <p class="pctwo">委托公司：{{ company }}</p> -->
    <div class="process">
      <p>操作流程</p>
      <div class="proliu">
        <div class="liuclass">
          <div>
            <div>1</div>
            <p>确认授权</p>
          </div>
          <div v-if="tap == 6">
            <div>2</div>
            <p>完成支付</p>
          </div>
          <div>
            <div>{{ tap == 6 ? "3" : "2" }}</div>
            <p>信息完善和实名认证</p>
          </div>
        </div>
      </div>
    </div>
    <div class="box bbnn">
      <van-checkbox checked-color="#FBA110" v-model="checked"></van-checkbox>
      <div class="box1">
        我已阅读并同意<a href="/protocol">《用户协议》</a>和
        <a href="/authorizationprotocol">授权声明</a>
      </div>
      <!-- <p @click="refuse">拒绝授权</p> -->
    </div>
    <button @click="success" :class="httpok == true ? 'bton' : 'bton2'">
      下一步
    </button>
    <!-- <div class="xieyi">
      <div>
        <van-radio-group v-model="radio">
          <van-radio name="1" checked-color="#FBA110"
            >我已阅读并同意《萝卜猎手隐私协议》</van-radio
          >
        </van-radio-group>
      </div>
      
    </div> -->

    <mskalert v-on:modifyismsk="modifyismsk" :ismsk="ismsk"></mskalert>
  </div>
</template>
<script>
import mskalert from "../mskalert/index.vue";
export default {
  components: {
    mskalert,
  },
  data() {
    return {
      msg: "授权中心",
      protocol: "",
      radio: "",
      ismsk: false, //是否显示遮罩层弹窗
      checked: "",
      tap: "",
      allmsg: "", //根据id查询返回的所有数据
      company: "", //企业信息
      httpok: false,
      idmsg:""
    };
  },
  async created() {
    // 二维码授权进入
    if (this.$route.query.isQrcode == "true") {
      this.qrcodemeth();
      return;
    }

    let themsg = this.$route.query.id;
    themsg = themsg.split("/")[0];

    this.idmsg = window.atob(themsg);
    await this.$http
      .post("/firm/v1/Login/reportInfo", {
        reqType: "Background",
        id: this.idmsg,
      })
      .then((res) => {
        this.httpok = true;
        let msg = JSON.parse(res.data);
        this.allmsg = msg.data;
        this.tap = msg.data.pay_type; //报告支付类型枚 1,2,3：套餐余量、4余额 6-移动端 用户个人支付
        this.money = msg.data.num; //价格
        this.id = msg.data.id; //报备id
        this.company = msg.data.company;
      });
  },
  methods: {
    qrcodemeth() {
      this.$http
        .post("/firm/v1/login/qrCodeInfo", {
          qr_id: window.atob(this.$route.query.qrId.split("/")[0]),
        })
        .then((res) => {
          this.httpok = true;
          this.tap = JSON.parse(res.data).data.qr_info.pay_type; //报告支付类型枚 1,2,3：套餐余量、4余额 6-移动端 用户个人支付
          this.allmsg = JSON.parse(res.data).data.qr_info;
          this.allmsg.isqrcode = true; //在信息填写页面判断是否是二维码授权进入
          this.allmsg.qrId = window.atob(this.$route.query.qrId.split("/")[0]); // 二维码id
          this.money = JSON.parse(res.data).data.qr_info.num; //价格
          this.id = JSON.parse(res.data).data.qr_info.id; //报备id

          this.company = JSON.parse(res.data).data.qr_info.company;
        });
    },
    refuse() {
      this.ismsk = true;
    },
    modifyismsk(msg) {
      this.ismsk = false;
    },
    success() {
      //如果数据未加载
      if (this.httpok == true) {
      } else {
        return;
      }
      if (this.checked == true) {
        this.$router.push({
          name: "backinformation",
          params: {
            allmsg: this.allmsg,
            idmsg:this.idmsg
          },
        });
      } else {
        this.$toast.fail("请勾选协议！");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.bton2 {
  width: 90%;
  color: #ffffff;
  background: #777777;
  margin: 0 5%;
  border: none;
  border-radius: 0.8rem;
  font-size: 0.36rem;
  padding: 0.2rem;
  height: 0.96rem;
}
.msg2 {
  margin: 0 1.2rem;
  margin-top: 0.13rem;
  margin-bottom: 0.3rem;
  font-weight: bold;
}
.msg2 > div:first-child {
  font-size: 0.28rem;
  color: #222222;
  line-height: 0.38rem;
  margin-bottom: 0.12rem;
}
.msg2 > div:nth-child(2) {
  color: #787878;
  font-size: 0.24rem;
  line-height: 0.38rem;
}
.smclass {
  margin: 0 1.2rem;
  font-weight: bold;
}
.smclass div {
  line-height: 0.48rem;
  font-size: 0.34rem;
}
.smclass > div:first-child {
  color: #f55814;
}
.smclass > div:nth-child(2) {
  color: #333333;
}
.topimg img {
  width: 3.22rem;
  height: 2.4rem;
}
.topimg {
  margin-top: 1.07rem;
  margin-bottom: 0.81rem;
  text-align: center;
}
.desclass {
  display: flex;
  align-items: center;
  margin-left: 10%;
  margin-top: 0.43rem;
  margin-bottom: 0.29rem;
}
/deep/ a {
  color: #f55613;
}
.desclass div:nth-child(2) {
  width: 30%;
  text-align: center;
  font-size: 0.34rem;
  color: #f55814;
  font-weight: bold;
}
.divone,
.divtwo {
  height: 1px;
  background-color: #ff894d;
  width: 30%;
}
.pcone {
  text-align: center;
  font-size: 0.28rem;
  color: #222222;
  margin-bottom: 0.12rem;
  font-weight: 500;
}
.pctwo {
  text-align: center;
  font-size: 0.24rem;
  color: #787878;
  font-weight: 500;
}
.process {
  margin-top: 0;
  width: 68%;
  margin-left: 16%;
}
.process > p {
  color: #222222;
  font-weight: 500;
  font-size: 0.34rem;
}
.proliu {
  display: flex;
  align-items: center;
  border-left-color: #f55814;
  border-left-style: solid;
  border-left-width: 0.1rem;
  margin-top: 0.69rem;
  margin-left: 0.2rem;
}
.liuclass div {
  margin-bottom: 0.79rem;
  display: flex;
  margin-left: -0.15rem;
}
.liuclass div div:first-child {
  background-color: #f55814;
  width: 0.5rem;
  height: 0.5rem;
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  line-height: 0.5rem;
  font-weight: 500;
  color: #ffffff;
  margin-right: 0.34rem;
  border-radius: 50%;
  margin-bottom: 0;
}
.liuclass div p {
  font-size: 0.36rem;
  color: #222222;
  font-weight: 500;
}
.liuclass div:last-child {
  margin-bottom: 0;
}
.centerclass {
  background-color: #ffffff;
  padding-bottom: 2rem;
  min-height: 100%;
}
.bbnn {
  margin-top: 1.26rem;
}
/* .xieyi {
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.3rem;
}
.xieyi div label {
  font-size: 0.26rem;
  color: #000000;
}
.xieyi div input:checked {
  background-color: #f55613;
  color: #f55613;
}
.xieyi > p {
  color: #f55613;
  font-weight: 400;
} */
.box {
  display: flex;
  margin-bottom: 0.3rem;
  line-height: 0.52rem;
  white-space: nowrap;
  margin-left: 8%;
}
.box .box1 {
  margin-left: 0.15rem;
  width: 4.42rem;
  height: 0.52rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 0.52rem;
}
.box p {
  margin-left: 0.7rem;
  width: 1.04rem;
  height: 0.52rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55613;
  line-height: 0.52rem;
}
.popups {
  z-index: 1100;
  position: fixed;
  background-color: #ffffff;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  overflow: hidden;
}
.popups p {
  margin: 0 0.69rem;
  min-width: 3.05rem;
  margin-bottom: 0.64rem;
  text-align: center;
  font-size: 0.3rem;
  color: #000000;
}
.popups > div:first-child {
  display: flex;
  justify-content: space-between;
  margin: 0.34rem 0.33rem 0.22rem 0;
}
.popups > div:first-child img {
  width: 0.34rem;
}
#btqone,
#btqtwo {
  width: 50%;
  margin: 0;
  border: none;
  padding: 0.19rem 0;
}
#btqone {
  background-color: #f65a16;
  color: #ffffff;
}
#btqtwo {
  background-color: #dcdcdc;
}
</style>
